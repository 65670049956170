@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Exo:ital,wght@0,100..900;1,100..900&family=Geologica:wght@100..900&family=Signika:wght@300..700&display=swap');

body{
    font-family: Geologica;
}
.button {
  background-color: #a7c957;
  border: 1px solid black;
  border-radius: var(--rounded-btn, 0.5rem);
  position: relative;
  overflow: hidden;
  color: black;
}

/* .button::before {
  content: '';
  position: absolute;
  top: 0;
  color: black;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #f2e8cf;
  transition: left 0.5s ease-in-out;
  z-index: 0;
}

.button:hover::before {
  left: 0;
  color: black;
} */

.button:hover {
  background-color: #f2e8cf;
  color: black;
  border: 2px solid black;
  border-radius: var(--rounded-btn, 0.5rem);
}
/* .button:hover::after {
  left: 0;
  color: black;
} */
/* .btn:hover{
    background-color: #a7c957;
    border: 1px solid black;
    border-radius: var(--rounded-btn, 0.5rem);
} */
/* NavBar */
.nav-button:hover{
    background-color: #a7c957;
    border-radius: var(--rounded-btn, 0.5rem);
}
.navlink:hover {
    background-color: #a7c957;
    border-radius: var(--rounded-btn, 0.5rem); 
  }
  .navlink.active {
    background-color: #a7c957;
    border-radius: var(--rounded-btn, 0.5rem); 
  }

  /* carosoul */
  .carosoul{
    height: 100%;
  }
  .cards{
    height: 100%;
  }
  .carousel-item img {
    object-fit: cover;
  }

  /* Tab List */
  .tab:hover {
    background-color: #a7c957;
    border: 1px solid black;
    border-radius: var(--rounded-btn, 0.5rem);
  }

  .tab.active {
    background-color: #a7c957;
    border: 1px solid black;
    border-radius: var(--rounded-btn, 0.5rem);
  }
  
  .tab-content {
    border: 1px solid #ddd;
    padding: 20px;
  }


  /* media queries */
  @media (max-width: 640px) {
    .tabs {
      display: none;
    }
    .dropdown {
      display: flex;
    }
    .dropdown option{
      text-align: center;
      margin: 2px;
    }
    .dropdown option:hover{
      text-align: center;
      margin: 2px;
      background-color: #a7c957;
      border: 1px solid black;
      border-radius: var(--rounded-btn, 0.5rem);
    }
  }
  
  @media (min-width: 641px) {
    .dropdown {
      display: none;
    }
  }